import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.slice.js";
import { columns } from "./config/index";
import { DashboardApi } from "@/api";
import { downloadPost } from "@/utils/util";
import { getCurrentDateTime, dateFormat } from "@/utils/dateutil";
export default {
  name: "dashboard",
  data: function data() {
    return {
      columns: columns,
      cbkCount: [],
      brickCount: [],
      upDate: 0,
      eventTypeList: [],
      dataSource: [],
      loading: false,
      eventType: "",
      beginTime: "",
      endTime: "",
      total: 0,
      page: 1,
      size: 10,
      visible: false,
      radioValue: 1,
      exportLoading: false
    };
  },
  computed: {},
  created: function created() {
    this.getUpDate();
    this.getEventTypeList();
    this.getList();
  },
  methods: {
    getUpDate: function getUpDate() {
      // this.getTodayCbk();
      // this.getYesterdayCbk();
      // this.getsevendaysCbk();
      // this.getThirtyDaysCbk();
      // this.getPlatformAllBrickNum();
      // this.getPlatformAvailableNum();
      // this.getPlatformFreezeNum();
      this.getCbkCount();
      this.getBrickCount();
      // this.upDate = getCurrentDate();
      this.upDate = dateFormat(getCurrentDateTime(), "yyyy-MM-dd hh:mm:ss");
    },
    // getCbkCount
    getCbkCount: function getCbkCount() {
      var _this = this;
      var params = {};
      DashboardApi.getCbkCount(params).then(function (res) {
        // console.log(res);
        res.forEach(function (item) {
          if (!item.num) item.num = 0;
          // item.num > 10000
          //   ? (item.num = parseInt(item.num / 10000) + "万")
          //   : item.num;
        });

        _this.cbkCount = res;
      }).catch(function (res) {});
    },
    // getBrickCount
    getBrickCount: function getBrickCount() {
      var _this2 = this;
      var params = {};
      DashboardApi.getBrickCount(params).then(function (res) {
        res.forEach(function (item) {
          if (!item.num) item.num = 0;
          if (item.title.indexOf("(") != -1) {
            _this2.$set(item, "sdate", item.title.substr(item.title.indexOf("(")));
            item.title = item.title.slice(0, item.title.indexOf("("));
            // console.log(item);
          }
          // item.num > 10000
          //   ? (item.num = parseInt(item.num / 10000) + "万")
          //   : item.num;
        });

        _this2.brickCount = res;
      }).catch(function (res) {});
    },
    // //今日发放CBK
    // getTodayCbk() {
    //   let params = {};
    //   DashboardApi.todayCbk(params)
    //     .then((res) => {
    //       // this.testData.todayCbk = res;
    //       this.testData[0].num = res;
    //     })
    //     .catch((res) => {});
    // },
    // //昨日发放CBK
    // getYesterdayCbk() {
    //   let params = {};
    //   DashboardApi.yesterdayCbk(params)
    //     .then((res) => {
    //       // this.testData.yesterdayCbk = res;
    //       this.testData[1].num = res;
    //     })
    //     .catch((res) => {});
    // },
    // //最近7天发放CBK
    // getsevendaysCbk() {
    //   let params = {};
    //   DashboardApi.sevendaysCbk(params)
    //     .then((res) => {
    //       // this.testData.sevendaysCbk = res;
    //       this.testData[2].num = res;
    //     })
    //     .catch((res) => {});
    // },
    // //最近30天发放CBK
    // getThirtyDaysCbk() {
    //   let params = {};
    //   DashboardApi.thirtyDaysCbk(params)
    //     .then((res) => {
    //       // this.testData.thirtyDaysCbk = res;
    //       this.testData[3].num = res;
    //     })
    //     .catch((res) => {
    //       console.log("catch:", res);
    //     });
    // },
    // //最近30天发放CBK
    // getThirtyDaysCbk() {
    //   let params = {};
    //   DashboardApi.thirtyDaysCbk(params)
    //     .then((res) => {
    //       // this.testData.thirtyDaysCbk = res;
    //       this.testData[4].num = res;
    //     })
    //     .catch((res) => {
    //       console.log("catch:", res);
    //     });
    // },
    // //全平台发放CBK（含冻结）
    // getPlatformAllBrickNum() {
    //   let params = {};
    //   DashboardApi.platformAllBrickNum(params)
    //     .then((res) => {
    //       // this.testData.platformAllBrickNum = res;
    //       this.testData[5].num = res;
    //     })
    //     .catch((res) => {
    //       console.log("catch:", res);
    //     });
    // },
    // //全平台可用CBK（不含冻结）
    // getPlatformAvailableNum() {
    //   let params = {};
    //   DashboardApi.platformAvailableNum(params)
    //     .then((res) => {
    //       // this.testData.platformAvailableNum = res;
    //       this.testData[6].num = res;
    //     })
    //     .catch((res) => {
    //       console.log("catch:", res);
    //     });
    // },
    // //全平台冻结CBK
    // getPlatformFreezeNum() {
    //   let params = {};
    //   DashboardApi.platformFreezeNum(params)
    //     .then((res) => {
    //       this.testData.platformFreezeNum = res;
    //     })
    //     .catch((res) => {
    //       console.log("catch:", res);
    //     });
    // },
    //CBK渠道
    getEventTypeList: function getEventTypeList() {
      var _this3 = this;
      var params = {};
      DashboardApi.getEventTypeList(params).then(function (res) {
        _this3.eventTypeList = res;
        console.log("getEventTypeList:", res);
      }).catch(function () {});
    },
    //CBK发放分日总计
    getList: function getList() {
      var _this4 = this;
      this.loading = true;
      var params = {
        eventtype: this.eventType,
        sevenday: this.radioValue === 1 ? true : false,
        page: this.page,
        size: this.size
      };
      if (!params.sevenday) {
        params.beginTime = this.beginTime;
        params.endTime = this.endTime;
      }
      DashboardApi.brickLogCountByTimeAndType(params).then(function (res) {
        // console.log("brickLogCountByTimeAndType:", res);
        _this4.dataSource = res.list;
        //接口分页给的string，转number
        _this4.total = res.total * 1;
        _this4.size = res.size * 1;
        _this4.loading = false;
      }).catch(function () {
        _this4.dataSource = [];
      }).finally(function () {
        setTimeout(function () {
          _this4.loading = false;
        }, 3000);
      });
    },
    selectChange: function selectChange(value, item) {
      console.log("selected ".concat(value, " "));
      this.eventType = value || "";
      this.getList();
    },
    radioChange: function radioChange(e) {
      // console.log("radio checked", e.target.value);
      if (e.target.value) return this.getList();
    },
    timeChange: function timeChange(item, time) {
      // console.log(item, time);
      this.beginTime = time[0];
      this.endTime = time[1];
      // console.log(this.beginTime, this.endTime);
      this.getList();
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    handleTableChange: function handleTableChange() {},
    exportWithdrawLog: function exportWithdrawLog() {
      var _this5 = this;
      if (this.exportLoading) return;
      this.exportLoading = true;
      var params = {
        eventtype: this.eventType,
        sevenday: this.radioValue === 1 ? true : false,
        begintime: this.beginTime,
        endtime: this.endTime,
        page: this.page,
        size: this.size
      };
      var url = "".concat(process.env.VUE_APP_DOMAIN, "api/brickservice/cbk/downLoadBrickLogCountByTimeAndType");
      // const url = `${process.env.VUE_APP_DOMAIN}api/communityservice/cbk/downLoadBrickLogCountByTimeAndType`;
      // const url = `${process.env.VUE_APP_DOMAIN}api/brickservice/cbk/downLoadBrickLogCountByTimeAndType`;

      downloadPost(url, params).then(function (res) {
        console.log(res);
        _this5.exportLoading = false;
      }).catch(function (error) {
        console.log(error);
        _this5.exportLoading = false;
      });
    }
  }
};